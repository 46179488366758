export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  File: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export enum AFDestination {
  OPEN_ACCOUNT_PAGE = 'OPEN_ACCOUNT_PAGE',
  OPEN_PAYMENTS_PAGE = 'OPEN_PAYMENTS_PAGE'
}

export enum AFIcon {
  Celebrate = 'Celebrate',
  Check = 'Check',
  ClipboardPlus = 'ClipboardPlus',
  CreditCard = 'CreditCard',
  CustomerReview = 'CustomerReview',
  Money = 'Money',
  Phone = 'Phone',
  SmartPhone = 'SmartPhone',
  TextMessage = 'TextMessage',
  Warning = 'Warning'
}

export type AFNotification = {
  __typename?: 'AFNotification';
  config?: Maybe<AFNotificationConfig>;
  createdAt: Scalars['Date']['output'];
  destination: Scalars['String']['output'];
  icon?: Maybe<AFIcon>;
  id: Scalars['String']['output'];
  notificationType?: Maybe<AFNotificationType>;
  subTitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type AFNotificationBadge = {
  __typename?: 'AFNotificationBadge';
  icon: Scalars['String']['output'];
  text: Scalars['String']['output'];
  variant: Scalars['String']['output'];
};

export type AFNotificationConfig = {
  __typename?: 'AFNotificationConfig';
  badges: Array<AFNotificationBadge>;
};

export type AFNotificationList = {
  __typename?: 'AFNotificationList';
  items: Array<AFNotification>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export enum AFNotificationType {
  Calls = 'Calls',
  Payments = 'Payments',
  Reviews = 'Reviews',
  TextMessages = 'TextMessages',
  Unknown = 'Unknown'
}

export enum AccountType {
  EMPLOYEE = 'EMPLOYEE',
  OWNER = 'OWNER',
  POINT_OF_SALE = 'POINT_OF_SALE',
  WORK_SHOP = 'WORK_SHOP'
}

export type AiChatMessageGql = {
  __typename?: 'AiChatMessageGql';
  content: Scalars['String']['output'];
  id: Scalars['String']['output'];
  role: AiMessageRole;
  sessionId: Scalars['String']['output'];
};

export enum AiMessageRole {
  assistant = 'assistant',
  function = 'function',
  system = 'system',
  user = 'user'
}

export type AmountMoney = {
  __typename?: 'AmountMoney';
  amount: Scalars['Int']['output'];
  currency: Currency;
};

export type AmountMoneyInput = {
  amount: Scalars['Int']['input'];
  currency: Currency;
};

export type Appointment = {
  __typename?: 'Appointment';
  appointmentType?: Maybe<AppointmentType>;
  callReport?: Maybe<CallReport>;
  car?: Maybe<Car>;
  customer?: Maybe<Customer>;
  estimatedPickupTime?: Maybe<Scalars['Date']['output']>;
  expectedDropoffTime: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  job?: Maybe<Job>;
  notes?: Maybe<Scalars['String']['output']>;
  requestedFrom?: Maybe<Scalars['String']['output']>;
  requestedOn?: Maybe<Scalars['Date']['output']>;
  shopLocation?: Maybe<ShopLocation>;
  status: AppointmentStatus;
};

export type AppointmentForTable = {
  __typename?: 'AppointmentForTable';
  expectedDropoffTime?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
};

export enum AppointmentStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  Completed = 'Completed',
  InProgress = 'InProgress',
  NoShow = 'NoShow',
  Requested = 'Requested'
}

export enum AppointmentType {
  dropoff = 'dropoff',
  waitAtShop = 'waitAtShop'
}

export type ApproveWorkOrderInput = {
  approvalAmount: Scalars['Int']['input'];
  approvedBy: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  authTokenType: AuthTokenType;
  employee?: Maybe<AuthResponseEmployee>;
  shopLocation?: Maybe<AuthResponseLocation>;
  token: Scalars['String']['output'];
  user: AuthResponseUser;
};

export type AuthResponseEmployee = {
  __typename?: 'AuthResponseEmployee';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

export type AuthResponseLocation = {
  __typename?: 'AuthResponseLocation';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AuthResponseUser = {
  __typename?: 'AuthResponseUser';
  authUserType: AccountType;
  companyName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
};

export enum AuthTokenType {
  ADMIN = 'ADMIN',
  FRONT_DESK = 'FRONT_DESK',
  LOGIN = 'LOGIN',
  MECHANIC = 'MECHANIC',
  SHOP = 'SHOP'
}

export type BankDeposit = {
  __typename?: 'BankDeposit';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
};

export type BankDepositInput = {
  amount: Scalars['Int']['input'];
};

export type CallReport = {
  __typename?: 'CallReport';
  appointment?: Maybe<Appointment>;
  callEndTime?: Maybe<Scalars['Date']['output']>;
  callStartTime?: Maybe<Scalars['Date']['output']>;
  createdAt: Scalars['Date']['output'];
  endReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  recordingURL?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
};

export type CampaignDefinition = {
  __typename?: 'CampaignDefinition';
  conversionEvent?: Maybe<ConvertionEventType>;
  deliveryType?: Maybe<CampaignDeliveryType>;
  id: Scalars['String']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  limitDeliveriesPerCustomer?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  metrics?: Maybe<CampaignMetrics>;
  name: Scalars['String']['output'];
  targeting?: Maybe<Scalars['String']['output']>;
  triggerSite?: Maybe<CampaignTriggerSite>;
  type?: Maybe<CampaignType>;
  url?: Maybe<Scalars['String']['output']>;
};

export type CampaignDefinitionInput = {
  conversionEvent?: InputMaybe<ConvertionEventType>;
  deliveryType?: InputMaybe<CampaignDeliveryType>;
  id: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  limitDeliveriesPerCustomer?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  targeting?: InputMaybe<Scalars['String']['input']>;
  triggerSite?: InputMaybe<CampaignTriggerSite>;
  type?: InputMaybe<CampaignType>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CampaignDefinitionListReturn = {
  __typename?: 'CampaignDefinitionListReturn';
  items: Array<CampaignDefinition>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type CampaignDefinitionOptions = {
  __typename?: 'CampaignDefinitionOptions';
  triggerSites: Array<CampaignTriggerSite>;
};

export enum CampaignDeliveryType {
  MANUAL = 'MANUAL',
  TRIGGERED = 'TRIGGERED'
}

export type CampaignMetrics = {
  __typename?: 'CampaignMetrics';
  numberOfDeliveries: Scalars['Int']['output'];
};

export type CampaignTriggerInput = {
  id: Scalars['String']['input'];
};

export type CampaignTriggerReturn = {
  __typename?: 'CampaignTriggerReturn';
  triggerStatus: TriggerStatus;
};

export enum CampaignTriggerSite {
  COMPLETED_JOB = 'COMPLETED_JOB',
  SMS_QUOTE_BUTTON_PRESSED = 'SMS_QUOTE_BUTTON_PRESSED'
}

export enum CampaignType {
  SMS = 'SMS'
}

export type Car = {
  __typename?: 'Car';
  colour?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  licensePlate?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  throwAway?: Maybe<Scalars['Boolean']['output']>;
  trim?: Maybe<Scalars['String']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type CarEditSuggestion = {
  __typename?: 'CarEditSuggestion';
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  suggestedVin?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type CarEditSuggestionInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type CarForTable = {
  __typename?: 'CarForTable';
  colour?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  licensePlate?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type CarListReturn = {
  __typename?: 'CarListReturn';
  items: Array<Car>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type CardPaymentInput = {
  jobId: Scalars['String']['input'];
  paymentAmount?: InputMaybe<Scalars['Int']['input']>;
};

export enum CardPaymentStatus {
  CANCELED = 'CANCELED',
  CANCEL_REQUESTED = 'CANCEL_REQUESTED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING'
}

export type CashPaymentInput = {
  amount: AmountMoneyInput;
  jobId: Scalars['String']['input'];
};

export type CashPaymentReturn = {
  __typename?: 'CashPaymentReturn';
  payment: Payment;
  remainder: AmountMoney;
};

export enum ChatFeedMessageType {
  AiChatAssistant = 'AiChatAssistant',
  AiChatFunction = 'AiChatFunction',
  AiChatSystem = 'AiChatSystem',
  AiChatUser = 'AiChatUser',
  TextMessageCustomer = 'TextMessageCustomer',
  TextMessageShop = 'TextMessageShop'
}

export type ChatMessageEmployee = {
  __typename?: 'ChatMessageEmployee';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

export type ChildAccountInput = {
  pasword: Scalars['String']['input'];
  type: ChildAccountType;
  username: Scalars['String']['input'];
};

export enum ChildAccountType {
  POINT_OF_SALE = 'POINT_OF_SALE',
  WORK_SHOP = 'WORK_SHOP'
}

export type CommandSearchCustomerItem = {
  __typename?: 'CommandSearchCustomerItem';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

export type CommandSearchExpenseItem = {
  __typename?: 'CommandSearchExpenseItem';
  expenseNumber: Scalars['Int']['output'];
  id: Scalars['String']['output'];
};

export type CommandSearchInput = {
  query: Scalars['String']['input'];
};

export type CommandSearchItem = CommandSearchCustomerItem | CommandSearchExpenseItem | CommandSearchWorkOrderItem;

export type CommandSearchOutput = {
  __typename?: 'CommandSearchOutput';
  items: Array<CommandSearchItem>;
};

export type CommandSearchWorkOrderItem = {
  __typename?: 'CommandSearchWorkOrderItem';
  id: Scalars['String']['output'];
  isArchived: Scalars['Boolean']['output'];
  jobNumber: Scalars['Int']['output'];
};

export type ConnectPaymentToJobInput = {
  jobId: Scalars['String']['input'];
  paymentId: Scalars['String']['input'];
};

export type ConnectedEmployeeProfile = {
  __typename?: 'ConnectedEmployeeProfile';
  email?: Maybe<Scalars['String']['output']>;
  employeeId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  joinDate?: Maybe<Scalars['Date']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  social?: Maybe<Scalars['String']['output']>;
};

export type ConnectedEmployer = {
  __typename?: 'ConnectedEmployer';
  companyName?: Maybe<Scalars['String']['output']>;
};

export type ConnectedEmployerProfile = {
  __typename?: 'ConnectedEmployerProfile';
  employeeProfile: ConnectedEmployeeProfile;
  employer: ConnectedEmployer;
};

export type ConnectedEmployerProfilesResult = {
  __typename?: 'ConnectedEmployerProfilesResult';
  items: Array<ConnectedEmployerProfile>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export enum ConvertionEventType {
  CHANGED_OIL = 'CHANGED_OIL'
}

export type CreateAppointmentInput = {
  appointmentType?: InputMaybe<AppointmentType>;
  carId?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  estimatedPickupTime?: InputMaybe<Scalars['Date']['input']>;
  expectedDropoffTime: Scalars['Date']['input'];
  id: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AppointmentStatus>;
};

export type CreateCarInput = {
  colour?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  trim?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateEmployeeAccountResult = {
  __typename?: 'CreateEmployeeAccountResult';
  resultCode: CreateEmployeeAccountResultCode;
  user?: Maybe<User>;
};

export enum CreateEmployeeAccountResultCode {
  ACCOUNT_ALREADY_EXISTS = 'ACCOUNT_ALREADY_EXISTS',
  CREATE_ACCOUNT_SUCCESSFUL = 'CREATE_ACCOUNT_SUCCESSFUL',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  INVALID_CODE = 'INVALID_CODE'
}

export type CreateInvoiceServiceDefinitionInput = {
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
  itemIds: Array<Scalars['String']['input']>;
};

export type CreateJobInput = {
  id: Scalars['String']['input'];
  shopLocationId: Scalars['String']['input'];
};

export type CreateStripeSessionUrlInput = {
  cancelUrl: Scalars['String']['input'];
  priceId: Scalars['String']['input'];
  successUrl: Scalars['String']['input'];
};

export enum Currency {
  CAD = 'CAD',
  EUR = 'EUR',
  USD = 'USD'
}

export type CustomInvoiceItem = {
  __typename?: 'CustomInvoiceItem';
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  type: InvoiceItemType;
};

export type CustomInvoiceItemInput = {
  name: Scalars['String']['input'];
  price: Scalars['Int']['input'];
  quantity: Scalars['Float']['input'];
  type: InvoiceItemType;
};

export type Customer = {
  __typename?: 'Customer';
  appointments: Array<Appointment>;
  callReports: Array<CallReport>;
  cars: Array<Car>;
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  jobs: Array<Job>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  secondaryPhoneNumber?: Maybe<Scalars['String']['output']>;
  shippingAddress?: Maybe<PostalAddress>;
  smsSubscriptionStatus?: Maybe<Scalars['String']['output']>;
  throwAway?: Maybe<Scalars['Boolean']['output']>;
};

export type CustomerForTable = {
  __typename?: 'CustomerForTable';
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  secondaryPhoneNumber?: Maybe<Scalars['String']['output']>;
  shippingAddress?: Maybe<PostalAddress>;
};

export type CustomerInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  secondaryPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  shippingAddress?: InputMaybe<PostalAddressInput>;
};

export type CustomerInvoiceLogoUpload = {
  __typename?: 'CustomerInvoiceLogoUpload';
  expiresInSeconds: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type CustomerInvoiceOptions = {
  __typename?: 'CustomerInvoiceOptions';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  companyEmail?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyPhoneNumber?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  creditCardFeesRecoupPercentage?: Maybe<Scalars['Int']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  locationShortName?: Maybe<Scalars['String']['output']>;
  logoUploadUrl: CustomerInvoiceLogoUpload;
  logoUrl?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
};

export type CustomerInvoiceOptionsInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  companyEmail?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  companyPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  creditCardFeesRecoupPercentage?: InputMaybe<Scalars['Int']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  locationShortName?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerListReturn = {
  __typename?: 'CustomerListReturn';
  items: Array<Customer>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type CustomerReview = {
  __typename?: 'CustomerReview';
  acquisitionChannel?: Maybe<Scalars['String']['output']>;
  customerServiceRating?: Maybe<Scalars['Int']['output']>;
  mechanicServiceRating?: Maybe<Scalars['Int']['output']>;
  negativeFeedback?: Maybe<Scalars['String']['output']>;
  netPromoterScore?: Maybe<Scalars['Int']['output']>;
  positiveFeedback?: Maybe<Scalars['String']['output']>;
  workOrder?: Maybe<CustomerReviewWorkOrder>;
};

export type CustomerReviewWorkOrder = {
  __typename?: 'CustomerReviewWorkOrder';
  id: Scalars['String']['output'];
  jobNumber: Scalars['String']['output'];
};

export type CustomerWorkOrder = {
  __typename?: 'CustomerWorkOrder';
  car?: Maybe<CustomerWorkOrderCar>;
  companyInformation: CustomerWorkOrderCompanyInformation;
  customerInformation: CustomerWorkOrderCustomerInformation;
  id: Scalars['String']['output'];
  invoice: CustomerWorkOrderInvoice;
  invoiceTitle: Scalars['String']['output'];
  jobNumber: Scalars['Int']['output'];
  paymentsResponse: CustomerWorkOrderPaymentsResponse;
  recommendations: Array<CustomerWorkOrderRecommendation>;
  workOrderCreationDate: Scalars['Date']['output'];
};

export type CustomerWorkOrderCar = {
  __typename?: 'CustomerWorkOrderCar';
  carOdometer?: Maybe<Scalars['Int']['output']>;
  colour?: Maybe<Scalars['String']['output']>;
  licensePlate?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  trim?: Maybe<Scalars['String']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type CustomerWorkOrderCompanyInformation = {
  __typename?: 'CustomerWorkOrderCompanyInformation';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  companyWebsite?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
};

export type CustomerWorkOrderCustomerInformation = {
  __typename?: 'CustomerWorkOrderCustomerInformation';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
};

export type CustomerWorkOrderInvoice = {
  __typename?: 'CustomerWorkOrderInvoice';
  approval?: Maybe<CustomerWorkOrderInvoiceApproval>;
  invoiceSummary: CustomerWorkOrderInvoiceSummary;
  notes?: Maybe<Scalars['String']['output']>;
  services: Array<CustomerWorkOrderService>;
};

export type CustomerWorkOrderInvoiceApproval = {
  __typename?: 'CustomerWorkOrderInvoiceApproval';
  approvalAmount?: Maybe<Scalars['Int']['output']>;
  approvedBy: Scalars['String']['output'];
  approvedOn: Scalars['Date']['output'];
};

export type CustomerWorkOrderInvoiceSummary = {
  __typename?: 'CustomerWorkOrderInvoiceSummary';
  convenienceFee?: Maybe<Scalars['Int']['output']>;
  subtotal: Scalars['Int']['output'];
  tax: Scalars['Int']['output'];
  total?: Maybe<Scalars['Int']['output']>;
  total_discount?: Maybe<Scalars['Int']['output']>;
  total_remaining_due: Scalars['Int']['output'];
};

export type CustomerWorkOrderPayment = {
  __typename?: 'CustomerWorkOrderPayment';
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  paymentType: PaymentType;
  refunds: Array<CustomerWorkOrderRefund>;
  settledAmount: Scalars['Int']['output'];
};

export type CustomerWorkOrderPaymentsResponse = {
  __typename?: 'CustomerWorkOrderPaymentsResponse';
  payments: Array<CustomerWorkOrderPayment>;
  squareLocationId?: Maybe<Scalars['String']['output']>;
};

export type CustomerWorkOrderRecommendation = {
  __typename?: 'CustomerWorkOrderRecommendation';
  cost: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  priority?: Maybe<WorkOrderRecommendationPriority>;
  recommendationImages: Array<RecommendationImage>;
  title: Scalars['String']['output'];
};

export type CustomerWorkOrderRefund = {
  __typename?: 'CustomerWorkOrderRefund';
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  refundType: RefundType;
  settledAmount: Scalars['Int']['output'];
};

export type CustomerWorkOrderService = {
  __typename?: 'CustomerWorkOrderService';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  items: Array<CustomerWorkOrderServiceItem>;
  totalCost: Scalars['Int']['output'];
};

export type CustomerWorkOrderServiceItem = {
  __typename?: 'CustomerWorkOrderServiceItem';
  cost?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  quantity?: Maybe<Scalars['Float']['output']>;
  totalCost: Scalars['Int']['output'];
};

export type DashboardData = {
  __typename?: 'DashboardData';
  customerReviews: Array<CustomerReview>;
  totalMoneySaved: Scalars['Int']['output'];
  totalTaxesReceived: Scalars['Int']['output'];
};

export type DayReport = {
  __typename?: 'DayReport';
  bankDeposits: Array<BankDeposit>;
  expenses: Array<Expense>;
  id: Scalars['String']['output'];
  payments: Array<Payment>;
  previousDayTillAmount: Scalars['Int']['output'];
  reportDate: Scalars['Date']['output'];
  tillAmount?: Maybe<Scalars['Int']['output']>;
};

export type DayReportInput = {
  id: Scalars['String']['input'];
  reportDate: Scalars['Date']['input'];
  tillAmount?: InputMaybe<Scalars['Int']['input']>;
  timezone: Scalars['String']['input'];
};

export type DeleteExpenseImageInput = {
  expenseId: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type DeleteWorkOrderRecommendationImageInput = {
  imageId: Scalars['String']['input'];
};

export type DeleteWorkOrderRecommendationInput = {
  recommendationId: Scalars['String']['input'];
};

export type ETransferPaymentInput = {
  amount: AmountMoneyInput;
  jobId: Scalars['String']['input'];
};

export type Employee = {
  __typename?: 'Employee';
  email?: Maybe<Scalars['String']['output']>;
  employeeId: Scalars['String']['output'];
  employeeType?: Maybe<EmployeeType>;
  firstName?: Maybe<Scalars['String']['output']>;
  hourlyRate?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  isAccountCreated: Scalars['Boolean']['output'];
  joinDate?: Maybe<Scalars['Date']['output']>;
  lastDayOnJob?: Maybe<Scalars['Date']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  social?: Maybe<Scalars['String']['output']>;
};

export type EmployeeConnectToEmployerAccountInput = {
  code: Scalars['String']['input'];
};

export type EmployeeConnectToEmployerAccountResult = {
  __typename?: 'EmployeeConnectToEmployerAccountResult';
  resultCode: EmployeeConnectToEmployerAccountResultCode;
};

export enum EmployeeConnectToEmployerAccountResultCode {
  CONNECTION_FAIL = 'CONNECTION_FAIL',
  CONNECTION_SUCCESS = 'CONNECTION_SUCCESS'
}

export type EmployeeCreateAccountInput = {
  loginCode: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type EmployeeInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  employeeType?: InputMaybe<EmployeeType>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hourlyRate?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  joinDate?: InputMaybe<Scalars['Date']['input']>;
  lastDayOnJob?: InputMaybe<Scalars['Date']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  social?: InputMaybe<Scalars['String']['input']>;
};

export type EmployeeJob = {
  __typename?: 'EmployeeJob';
  car?: Maybe<Car>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  jobNumber: Scalars['Int']['output'];
  mechanicShopStatus?: Maybe<JobMechanicShopStatus>;
};

export type EmployeeJobsReturn = {
  __typename?: 'EmployeeJobsReturn';
  items: Array<EmployeeJob>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type EmployeeLoginInput = {
  loginCode: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type EmployeeMutation = {
  __typename?: 'EmployeeMutation';
  _empty?: Maybe<Scalars['String']['output']>;
  employeeId: Scalars['String']['output'];
  updateJob: EmployeeMutationUpdateJobResponse;
};


export type EmployeeMutationupdateJobArgs = {
  jobId: Scalars['String']['input'];
};

export type EmployeeMutationUpdateJobResponse = {
  __typename?: 'EmployeeMutationUpdateJobResponse';
  jobId: Scalars['String']['output'];
  updateMechanicShopStatus: EmployeeJob;
};


export type EmployeeMutationUpdateJobResponseupdateMechanicShopStatusArgs = {
  status: JobMechanicShopStatus;
};

export type EmployeeObject = {
  __typename?: 'EmployeeObject';
  _empty?: Maybe<Scalars['String']['output']>;
  employeeId: Scalars['String']['output'];
  listWorkOrders: EmployeeJobsReturn;
};


export type EmployeeObjectlistWorkOrdersArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  options: ListEmployeeWorkOrdersOptions;
};

export type EmployeeSendPhoneCodeInput = {
  phoneNumber: Scalars['String']['input'];
};

export type EmployeeSendPhoneCodeResult = {
  __typename?: 'EmployeeSendPhoneCodeResult';
  resultCode?: Maybe<EmployeeSendPhoneCodeResultCode>;
};

export enum EmployeeSendPhoneCodeResultCode {
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
  SUCCESSFULLY_POSSIBLY_SENT_CODE = 'SUCCESSFULLY_POSSIBLY_SENT_CODE'
}

export enum EmployeeType {
  FRONT_DESK = 'FRONT_DESK',
  MECHANIC = 'MECHANIC'
}

export type EmployeeWorkOrderListOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type EmployeeWorkOrderListResponse = {
  __typename?: 'EmployeeWorkOrderListResponse';
  items: Array<EmployeeJob>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type Expense = {
  __typename?: 'Expense';
  amount?: Maybe<Scalars['Int']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  employee?: Maybe<Employee>;
  expenseNetTotal: Scalars['Int']['output'];
  expenseNumber: Scalars['Int']['output'];
  expenseRefunds: Array<ExpenseRefund>;
  id: Scalars['String']['output'];
  images: Array<ExpenseImage>;
  isAIGenerated?: Maybe<Scalars['Boolean']['output']>;
  job?: Maybe<Job>;
  paidAt?: Maybe<Scalars['Date']['output']>;
  paidTo?: Maybe<Scalars['String']['output']>;
  paymentType?: Maybe<ExpensePaymentType>;
  taxAmount?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type ExpenseCategoriesListReturn = {
  __typename?: 'ExpenseCategoriesListReturn';
  items: Array<ExpenseCategory>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type ExpenseCategory = {
  __typename?: 'ExpenseCategory';
  name: Scalars['String']['output'];
};

export type ExpenseConnection = {
  id: Scalars['String']['input'];
  type: ExpenseConnectionType;
};

export enum ExpenseConnectionType {
  EMPLOYEE = 'EMPLOYEE',
  JOB = 'JOB'
}

export type ExpenseFileUploadCodeResponse = {
  __typename?: 'ExpenseFileUploadCodeResponse';
  code: Scalars['String']['output'];
  expiresInSeconds: Scalars['Int']['output'];
};

export type ExpenseFileUploadUrlResponse = {
  __typename?: 'ExpenseFileUploadUrlResponse';
  expiresInSeconds: Scalars['Int']['output'];
  uploadUrl: Scalars['String']['output'];
};

export type ExpenseImage = {
  __typename?: 'ExpenseImage';
  fileName: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ExpenseInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  paidAt?: InputMaybe<Scalars['Date']['input']>;
  paidTo?: InputMaybe<Scalars['String']['input']>;
  paymentType?: InputMaybe<ExpensePaymentType>;
  taxAmount?: InputMaybe<Scalars['Int']['input']>;
};

export type ExpenseListReturn = {
  __typename?: 'ExpenseListReturn';
  items: Array<Expense>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export enum ExpensePaymentType {
  CARD = 'CARD',
  TILL_CASH = 'TILL_CASH'
}

export type ExpenseRefund = {
  __typename?: 'ExpenseRefund';
  amount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  refundedOn?: Maybe<Scalars['Date']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type ExpenseRefundInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  expenseId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  refundedOn?: InputMaybe<Scalars['Date']['input']>;
};

export type ExpenseUploadUrlsRespone = {
  __typename?: 'ExpenseUploadUrlsRespone';
  uploadCode: ExpenseFileUploadCodeResponse;
  uploadUrl: ExpenseFileUploadUrlResponse;
};

export type ExpensesListOptions = {
  disablePagination?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Date']['input']>;
  includeArchivedExpenses?: InputMaybe<Scalars['Boolean']['input']>;
  includeJob?: InputMaybe<Scalars['Boolean']['input']>;
  includeRefunds?: InputMaybe<Scalars['Boolean']['input']>;
  retrieveArchivedExpensesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  shopLocationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  startTime?: InputMaybe<Scalars['Date']['input']>;
};

export type GPTDashboardDataInput = {
  query: Scalars['String']['input'];
  request_timezone: Scalars['String']['input'];
};

export type GPTDashboardDataResponse = {
  __typename?: 'GPTDashboardDataResponse';
  graphType: GPTGraphType;
  plots: Array<GPTPlot>;
};

export enum GPTGraphType {
  BAR = 'BAR',
  ERROR = 'ERROR',
  LINE = 'LINE',
  NUMBER = 'NUMBER'
}

export type GPTPlot = {
  __typename?: 'GPTPlot';
  data: Array<GPTPlotData>;
  label: Scalars['String']['output'];
  stackId: Scalars['String']['output'];
};

export type GPTPlotData = {
  __typename?: 'GPTPlotData';
  date: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type GenerateLoginCodeForPhonNumberInput = {
  phoneNumber: Scalars['String']['input'];
};

export type GenerateLoginCodeForPhonNumberResult = {
  __typename?: 'GenerateLoginCodeForPhonNumberResult';
  resultCode: LoginWithPhoneNumberResultCode;
};

export type GenerateStripePaymentClientSecretInput = {
  amount: Scalars['Int']['input'];
  shopLocationId: Scalars['String']['input'];
  workOrderId: Scalars['String']['input'];
};

export type GetAllInvoiceItemDefinitionsResponse = {
  __typename?: 'GetAllInvoiceItemDefinitionsResponse';
  items: Array<InvoiceItemDefinition>;
};

export type GetChatMessagesForSessionResponse = {
  __typename?: 'GetChatMessagesForSessionResponse';
  messages: Array<AiChatMessageGql>;
};

export type GetTodaysJobsResponse = {
  __typename?: 'GetTodaysJobsResponse';
  count: Scalars['Int']['output'];
  items: Array<Maybe<Job>>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export enum GraphQLUserErrors {
  INVALID_SUBSCRIPTION = 'INVALID_SUBSCRIPTION',
  UNAUTHENTICATED = 'UNAUTHENTICATED'
}

export type InventoryItem = {
  __typename?: 'InventoryItem';
  id: Scalars['String']['output'];
  inventoryAmount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
};

export type InventoryItemInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Int']['input'];
};

export type InventoryItemListReturn = {
  __typename?: 'InventoryItemListReturn';
  items: Array<InventoryItem>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  invoiceMetadata: InvoiceMetadata;
  invoiceSummary: InvoiceSummary;
  services: Array<InvoiceService>;
  throwAway?: Maybe<Scalars['Boolean']['output']>;
};

export type InvoiceAproval = {
  __typename?: 'InvoiceAproval';
  approvalAmount?: Maybe<Scalars['Int']['output']>;
  approvedBy: Scalars['String']['output'];
  approvedOn: Scalars['Date']['output'];
};

export type InvoiceForTable = {
  __typename?: 'InvoiceForTable';
  approvedOn?: Maybe<Scalars['Date']['output']>;
  remainingWithCreditCardFeesRecoup: Scalars['Int']['output'];
  remainingWithOutCreditCardFeesRecoup: Scalars['Int']['output'];
};

export type InvoiceInput = {
  customItems: Array<CustomInvoiceItemInput>;
  discount: Scalars['Float']['input'];
  id: Scalars['String']['input'];
  items: Array<InvoiceItemInput>;
  jobId: Scalars['String']['input'];
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  amount?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  quantity?: Maybe<Scalars['Float']['output']>;
};

export type InvoiceItemDefinition = {
  __typename?: 'InvoiceItemDefinition';
  cost?: Maybe<Scalars['Int']['output']>;
  description: Scalars['String']['output'];
  grouping?: Maybe<InvoiceItemGrouping>;
  id: Scalars['String']['output'];
  tax_percentage?: Maybe<Scalars['Float']['output']>;
};

export type InvoiceItemGrouping = {
  __typename?: 'InvoiceItemGrouping';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type InvoiceItemGroupingInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type InvoiceItemInput = {
  id: Scalars['String']['input'];
  quantity: Scalars['Float']['input'];
  type: InvoiceItemType;
};

export enum InvoiceItemType {
  CUSTOM = 'CUSTOM',
  FROM_DEFINITION = 'FROM_DEFINITION',
  SQUARE = 'SQUARE'
}

export type InvoiceMetadata = {
  __typename?: 'InvoiceMetadata';
  approval?: Maybe<InvoiceAproval>;
  createdAt: Scalars['Date']['output'];
  discount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  taxPercentage?: Maybe<Scalars['Float']['output']>;
};

export type InvoiceMetadataInput = {
  note: Scalars['String']['input'];
};

export type InvoiceService = {
  __typename?: 'InvoiceService';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  items: Array<InvoiceServiceItem>;
};

export type InvoiceServiceDefinition = {
  __typename?: 'InvoiceServiceDefinition';
  default_items: Array<InvoiceItemDefinition>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type InvoiceServiceItem = {
  __typename?: 'InvoiceServiceItem';
  cost?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  tax_percentage?: Maybe<Scalars['Float']['output']>;
};

export type InvoiceSummary = {
  __typename?: 'InvoiceSummary';
  creditCardFeesRecoupPercentage: Scalars['Float']['output'];
  invoiceWarnings: Array<InvoiceSummaryWarning>;
  remainingWithCreditCardFeesRecoup: Scalars['Int']['output'];
  remainingWithOutCreditCardFeesRecoup: Scalars['Int']['output'];
  subtotals?: Maybe<InvoiceSummarySubtotals>;
};

export type InvoiceSummarySubtotals = {
  __typename?: 'InvoiceSummarySubtotals';
  cardFee: Scalars['Int']['output'];
  subtotalBeforeTaxAndDiscounts: Scalars['Int']['output'];
  tax: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  totalDiscount: Scalars['Int']['output'];
  totalTaxPaid: Scalars['Int']['output'];
};

export type InvoiceSummaryWarning = {
  __typename?: 'InvoiceSummaryWarning';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  primary_action_text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum InvoiceSummaryWarningActionType {
  UpdateTaxPercentage = 'UpdateTaxPercentage'
}

export type Job = {
  __typename?: 'Job';
  appointment?: Maybe<Appointment>;
  car?: Maybe<Car>;
  customer?: Maybe<Customer>;
  employee?: Maybe<Employee>;
  expenses: Array<Expense>;
  images: Array<JobImage>;
  invoice?: Maybe<Invoice>;
  jobMetadata: JobMetadata;
  jobReview?: Maybe<JobReview>;
  payments: Array<Payment>;
  recommendations: Array<WorkOrderRecommendation>;
  serviceAdviser?: Maybe<JobServiceAdviser>;
  shopLocation?: Maybe<ShopLocation>;
  summary?: Maybe<JobSummary>;
  throwAway?: Maybe<Scalars['Boolean']['output']>;
};

export enum JobConnectionType {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL'
}

export enum JobDeletionStatus {
  DELETED = 'DELETED',
  NOT_DELETED = 'NOT_DELETED'
}

export type JobForTable = {
  __typename?: 'JobForTable';
  appointment?: Maybe<AppointmentForTable>;
  car?: Maybe<CarForTable>;
  customer?: Maybe<CustomerForTable>;
  invoice?: Maybe<InvoiceForTable>;
  jobMetadata: JobForTableMetadata;
};

export type JobForTableMetadata = {
  __typename?: 'JobForTableMetadata';
  carOdometer?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  jobNumber: Scalars['Int']['output'];
  mechanicShopStatus?: Maybe<JobMechanicShopStatus>;
  timeCompleted?: Maybe<Scalars['Date']['output']>;
  timeKeysReceived?: Maybe<Scalars['Date']['output']>;
  timePickedUp?: Maybe<Scalars['Date']['output']>;
  totalPaid?: Maybe<Scalars['Int']['output']>;
  workOrderId: Scalars['String']['output'];
};

export type JobImage = {
  __typename?: 'JobImage';
  id: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type JobListForTableReturn = {
  __typename?: 'JobListForTableReturn';
  count: Scalars['Int']['output'];
  items: Array<JobForTable>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type JobListReturn = {
  __typename?: 'JobListReturn';
  count: Scalars['Int']['output'];
  items: Array<Job>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export enum JobMechanicShopStatus {
  CREATED = 'CREATED',
  CUSTOMER_PICKED_UP = 'CUSTOMER_PICKED_UP',
  KEYS_RECEIVED = 'KEYS_RECEIVED',
  MECHANIC_COMPLETED = 'MECHANIC_COMPLETED',
  MECHANIC_IN_PROGRESS = 'MECHANIC_IN_PROGRESS',
  SALE = 'SALE'
}

export type JobMetadata = {
  __typename?: 'JobMetadata';
  carOdometer?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  estimateAmount?: Maybe<Scalars['Float']['output']>;
  frontDeskComments?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  jobNumber: Scalars['Int']['output'];
  mechanicComments?: Maybe<Scalars['String']['output']>;
  mechanicShopStatus?: Maybe<JobMechanicShopStatus>;
  status: JobStatus;
  timeCompleted?: Maybe<Scalars['Date']['output']>;
  timeKeysReceived?: Maybe<Scalars['Date']['output']>;
  timePickedUp?: Maybe<Scalars['Date']['output']>;
  totalDue?: Maybe<Scalars['Int']['output']>;
  totalPaid?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<JobType>;
  workOrderId: Scalars['String']['output'];
};

export enum JobPaymentStatus {
  FULLY_PAID = 'FULLY_PAID',
  PAYMENT_REQUIRED = 'PAYMENT_REQUIRED'
}

export type JobReview = {
  __typename?: 'JobReview';
  acquisitionChannel?: Maybe<Scalars['String']['output']>;
  customerServiceRating?: Maybe<Scalars['Int']['output']>;
  mechanicServiceRating?: Maybe<Scalars['Int']['output']>;
  negativeFeedback?: Maybe<Scalars['String']['output']>;
  netPromoterScore?: Maybe<Scalars['Int']['output']>;
  positiveFeedback?: Maybe<Scalars['String']['output']>;
};

export type JobServiceAdviser = {
  __typename?: 'JobServiceAdviser';
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export enum JobStatus {
  COMPLETE = 'COMPLETE',
  NOT_STARTED = 'NOT_STARTED'
}

export type JobSummary = {
  __typename?: 'JobSummary';
  discountAmount: Scalars['Int']['output'];
  subtotal: Scalars['Int']['output'];
  taxAmount: Scalars['Int']['output'];
  totalPaid: Scalars['Int']['output'];
};

export enum JobType {
  MECHANIC = 'MECHANIC',
  OIL = 'OIL',
  OTHER = 'OTHER',
  PURCHASE = 'PURCHASE',
  TIRE = 'TIRE',
  UNDER_COAT = 'UNDER_COAT',
  UNKNOWN = 'UNKNOWN'
}

export type ListAppointmentsResponse = {
  __typename?: 'ListAppointmentsResponse';
  items: Array<Appointment>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type ListBankDepositsResponse = {
  __typename?: 'ListBankDepositsResponse';
  items: Array<BankDeposit>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type ListChatsForCustomerResponse = {
  __typename?: 'ListChatsForCustomerResponse';
  chats: Array<NewChatMessage>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type ListDayReportsResponse = {
  __typename?: 'ListDayReportsResponse';
  items: Array<DayReport>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type ListEmployeeShiftsOptions = {
  disablePagination?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Date']['input']>;
  showOnlyFinishedShifts?: InputMaybe<Scalars['Boolean']['input']>;
  showOnlyUnfinishedShifts?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['Date']['input']>;
};

export type ListEmployeeWorkOrdersOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  listType: ListWorkordersType;
};

export type ListEmployeesOnShiftOptions = {
  employeeTypesFilter?: InputMaybe<Array<EmployeeType>>;
};

export type ListEmployeesResult = {
  __typename?: 'ListEmployeesResult';
  items: Array<Employee>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type ListInvoiceItemDefinitionsInput = {
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ListInvoiceItemDefinitionsResponse = {
  __typename?: 'ListInvoiceItemDefinitionsResponse';
  items: Array<InvoiceItemDefinition>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type ListInvoiceItemGroupingsResponse = {
  __typename?: 'ListInvoiceItemGroupingsResponse';
  items: Array<InvoiceItemGrouping>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type ListInvoiceServiceDefinitionsInput = {
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ListInvoiceServiceDefinitionsResponse = {
  __typename?: 'ListInvoiceServiceDefinitionsResponse';
  items: Array<InvoiceServiceDefinition>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type ListJobsForDashboardInput = {
  endTime?: InputMaybe<Scalars['Date']['input']>;
  shopLocationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  startTime?: InputMaybe<Scalars['Date']['input']>;
};

export type ListJobsInput = {
  archiveStatuses?: InputMaybe<Array<JobDeletionStatus>>;
  filterStatuses?: InputMaybe<Array<JobMechanicShopStatus>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  paymentStatuses?: InputMaybe<Array<JobPaymentStatus>>;
  shopLocationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  showArchivedWorkOrders?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ListOptions = {
  disablePagination?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Date']['input']>;
  retreiveDeletedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['Date']['input']>;
};

export type ListPaymentsInput = {
  disablePagination?: InputMaybe<Scalars['Boolean']['input']>;
  endTime?: InputMaybe<Scalars['Date']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  shopLocationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  startTime?: InputMaybe<Scalars['Date']['input']>;
};

export type ListShiftsOptions = {
  endTime?: InputMaybe<Scalars['Date']['input']>;
  pageSize: Scalars['Int']['input'];
  startTime?: InputMaybe<Scalars['Date']['input']>;
};

export enum ListWorkordersType {
  UnfinishedWorkOrders = 'UnfinishedWorkOrders'
}

export type LoginAsShopInput = {
  shopLocationId: Scalars['String']['input'];
};

export type LoginEmployeeAccountResult = {
  __typename?: 'LoginEmployeeAccountResult';
  resultCode: LoginEmployeeAccountResultCode;
  user?: Maybe<User>;
};

export enum LoginEmployeeAccountResultCode {
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  INVALID_CODE = 'INVALID_CODE',
  LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL',
  USER_NOT_FOUND = 'USER_NOT_FOUND'
}

export type LoginEmployeeInput = {
  employeeId: Scalars['String']['input'];
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export enum LoginWithPhoneNumberResultCode {
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
  SUCCESSFULLY_POSSIBLY_SENT_CODE = 'SUCCESSFULLY_POSSIBLY_SENT_CODE'
}

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']['output']>;
  addExpenseCategory?: Maybe<ExpenseCategory>;
  addWaitlistItem?: Maybe<Scalars['Boolean']['output']>;
  approveWorkOrder: Scalars['Boolean']['output'];
  archiveExpense: Scalars['Boolean']['output'];
  archiveJob: Scalars['Boolean']['output'];
  clearActivityFeedBadging: Scalars['Boolean']['output'];
  connectExpenseToWorkOrder: Scalars['Boolean']['output'];
  connectPaymentToJob: Payment;
  connectToEmployerAccount: EmployeeConnectToEmployerAccountResult;
  connectToSquare?: Maybe<Scalars['Boolean']['output']>;
  createAppointment: Appointment;
  createBankDeposit: BankDeposit;
  createCar: Car;
  createChildAccount: User;
  createCustomerOnlinePayment: CustomerWorkOrderPayment;
  createEmployeeAccount: CreateEmployeeAccountResult;
  createExpenseForWorkOrder: Expense;
  createInvoiceServiceDefinition: InvoiceServiceDefinition;
  createNewJob: Job;
  createOnlinePayment: Payment;
  createRecommendationsChatMesage: RecommendationChatMessageResponse;
  createShopLocation: ShopLocation;
  createStripePortalSessionUrl: StripePortalSession;
  createStripeSubscriptionSessionUrl: StripeSubscriptionSession;
  deleteCar?: Maybe<Scalars['Boolean']['output']>;
  deleteCustomer?: Maybe<Scalars['Boolean']['output']>;
  deleteExpenseImage: Scalars['Boolean']['output'];
  deleteExpenseRefund: Scalars['Boolean']['output'];
  disconnectExpenseFromWorkOrder: Scalars['Boolean']['output'];
  disconnectFromSquare?: Maybe<Scalars['Boolean']['output']>;
  employeeEndShift: Shift;
  employeeMutation: EmployeeMutation;
  employeeStartShift: Shift;
  expensePictureUploadedSuccessfully: Scalars['Boolean']['output'];
  generateStripePaymentClientSecret: Scalars['String']['output'];
  initializeCardPayment: Payment;
  recordCashPayment: CashPaymentReturn;
  recordPayment: Payment;
  refundPayment: Refund;
  requestAppointment: RequestAppointmentResponse;
  resetPassword: Scalars['Boolean']['output'];
  savePhoneNotifictionsToken: PhoneNotificationsToken;
  sendEmployeeAccountCode: Scalars['Boolean']['output'];
  sendResetPasswordEmail: Scalars['Boolean']['output'];
  sendSignWorkOrderSMS: NewChatMessage;
  sendTextMessage: Scalars['Boolean']['output'];
  sendWorkOrderCustomEmail: Scalars['Boolean']['output'];
  sendWorkOrderEmail: Scalars['Boolean']['output'];
  setCustomerInvoiceOptions: CustomerInvoiceOptions;
  setEmployee: Employee;
  setSquareOptions: SquareConnection;
  setUser: User;
  signup: AuthResponse;
  syncSquarePayments: SquareConnection;
  triggerCampaign?: Maybe<CampaignTriggerReturn>;
  triggerCampaignSite?: Maybe<TriggerCampaignSiteReturn>;
  unArchiveExpense: Scalars['Boolean']['output'];
  unarchiveJob: Scalars['Boolean']['output'];
  updateAppointment: Appointment;
  updateAppointmentStatus: Scalars['Boolean']['output'];
  updateCar: Car;
  updateInvoice: UpdateInvoiceResponse;
  updateInvoiceNew: UpdateInvoiceNewResponse;
  updateJob: UpdateJobResponse;
  updateShift: UpdateShiftResponse;
  updateShopLocation: ShopLocation;
  upsertCampaignDefinition: CampaignDefinition;
  upsertCustomer: Customer;
  upsertDayReport: DayReport;
  upsertExpense: Expense;
  upsertExpenseRefund: ExpenseRefund;
  upsertInventoryItem: InventoryItem;
  upsertInvoiceItemDefinition: InvoiceItemDefinition;
  upsertInvoiceItemGrouping: InvoiceItemGrouping;
  upsertInvoiceServiceDefinition: InvoiceServiceDefinition;
  upsertShopService: ShopService;
  workOrderRecommendationMutation: WorkOrderRecommendationMutation;
};


export type MutationaddExpenseCategoryArgs = {
  name: Scalars['String']['input'];
};


export type MutationaddWaitlistItemArgs = {
  input: WaitlistItemInput;
};


export type MutationapproveWorkOrderArgs = {
  input: ApproveWorkOrderInput;
};


export type MutationarchiveExpenseArgs = {
  expenseId: Scalars['String']['input'];
};


export type MutationarchiveJobArgs = {
  id: Scalars['String']['input'];
};


export type MutationconnectExpenseToWorkOrderArgs = {
  expenseId: Scalars['String']['input'];
  workOrderId: Scalars['String']['input'];
};


export type MutationconnectPaymentToJobArgs = {
  input: ConnectPaymentToJobInput;
};


export type MutationconnectToEmployerAccountArgs = {
  input: EmployeeConnectToEmployerAccountInput;
};


export type MutationconnectToSquareArgs = {
  code: Scalars['String']['input'];
  shopLocationId: Scalars['String']['input'];
};


export type MutationcreateAppointmentArgs = {
  input: CreateAppointmentInput;
  shopLocationId: Scalars['String']['input'];
};


export type MutationcreateBankDepositArgs = {
  input: BankDepositInput;
};


export type MutationcreateCarArgs = {
  car: CreateCarInput;
};


export type MutationcreateChildAccountArgs = {
  input: ChildAccountInput;
};


export type MutationcreateCustomerOnlinePaymentArgs = {
  input: OnlinePaymentInput;
};


export type MutationcreateEmployeeAccountArgs = {
  input: EmployeeCreateAccountInput;
};


export type MutationcreateExpenseForWorkOrderArgs = {
  workOrderId: Scalars['String']['input'];
};


export type MutationcreateInvoiceServiceDefinitionArgs = {
  input: CreateInvoiceServiceDefinitionInput;
};


export type MutationcreateNewJobArgs = {
  jobInput: CreateJobInput;
};


export type MutationcreateOnlinePaymentArgs = {
  onlinePaymentInput: OnlinePaymentInput;
};


export type MutationcreateRecommendationsChatMesageArgs = {
  recommendationId: Scalars['String']['input'];
};


export type MutationcreateShopLocationArgs = {
  input: ShopLocationInput;
};


export type MutationcreateStripeSubscriptionSessionUrlArgs = {
  input: CreateStripeSessionUrlInput;
};


export type MutationdeleteCarArgs = {
  id: Scalars['String']['input'];
};


export type MutationdeleteCustomerArgs = {
  id: Scalars['String']['input'];
};


export type MutationdeleteExpenseImageArgs = {
  input: DeleteExpenseImageInput;
};


export type MutationdeleteExpenseRefundArgs = {
  expenseRefundId: Scalars['String']['input'];
};


export type MutationdisconnectExpenseFromWorkOrderArgs = {
  expenseId: Scalars['String']['input'];
};


export type MutationdisconnectFromSquareArgs = {
  shopLocationId: Scalars['String']['input'];
};


export type MutationemployeeMutationArgs = {
  employeeId: Scalars['String']['input'];
};


export type MutationexpensePictureUploadedSuccessfullyArgs = {
  uploadId: Scalars['String']['input'];
};


export type MutationgenerateStripePaymentClientSecretArgs = {
  input: GenerateStripePaymentClientSecretInput;
};


export type MutationinitializeCardPaymentArgs = {
  input: CardPaymentInput;
};


export type MutationrecordCashPaymentArgs = {
  cashPaymentInput: CashPaymentInput;
};


export type MutationrecordPaymentArgs = {
  paymentInput: PaymentRecordInput;
};


export type MutationrefundPaymentArgs = {
  input: RefundPaymentInput;
};


export type MutationrequestAppointmentArgs = {
  cloudflareTurnstileToken: Scalars['String']['input'];
  input: RequestAppointmentInput;
};


export type MutationresetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationsavePhoneNotifictionsTokenArgs = {
  tokenInput: PhoneNotificationsTokenInput;
};


export type MutationsendEmployeeAccountCodeArgs = {
  employeeId: Scalars['String']['input'];
};


export type MutationsendResetPasswordEmailArgs = {
  input: SendResetPasswordEmailInput;
};


export type MutationsendSignWorkOrderSMSArgs = {
  jobId: Scalars['String']['input'];
};


export type MutationsendTextMessageArgs = {
  input: SendTextMessagesInput;
};


export type MutationsendWorkOrderCustomEmailArgs = {
  emailInput: SendWorkOrderEmailInput;
};


export type MutationsendWorkOrderEmailArgs = {
  jobId: Scalars['String']['input'];
};


export type MutationsetCustomerInvoiceOptionsArgs = {
  input: CustomerInvoiceOptionsInput;
  shopLocationId: Scalars['String']['input'];
};


export type MutationsetEmployeeArgs = {
  employee: EmployeeInput;
};


export type MutationsetSquareOptionsArgs = {
  options: SquareOptionsInput;
  shopLocationId: Scalars['String']['input'];
};


export type MutationsetUserArgs = {
  user: UserInformationInput;
};


export type MutationsignupArgs = {
  input: SignupInput;
};


export type MutationsyncSquarePaymentsArgs = {
  shopLocationId: Scalars['String']['input'];
};


export type MutationtriggerCampaignArgs = {
  input: CampaignTriggerInput;
};


export type MutationtriggerCampaignSiteArgs = {
  campaignTriggerSite: CampaignTriggerSite;
  customerId: Scalars['String']['input'];
};


export type MutationunArchiveExpenseArgs = {
  expenseId: Scalars['String']['input'];
};


export type MutationunarchiveJobArgs = {
  id: Scalars['String']['input'];
};


export type MutationupdateAppointmentArgs = {
  input: UpdateAppointmentInput;
};


export type MutationupdateAppointmentStatusArgs = {
  id: Scalars['String']['input'];
  newAppointmentStatus: AppointmentStatus;
};


export type MutationupdateCarArgs = {
  car: UpdateCarInput;
};


export type MutationupdateInvoiceArgs = {
  invoiceId: Scalars['String']['input'];
};


export type MutationupdateInvoiceNewArgs = {
  invoiceId: Scalars['String']['input'];
};


export type MutationupdateJobArgs = {
  id: Scalars['String']['input'];
};


export type MutationupdateShiftArgs = {
  shiftId: Scalars['String']['input'];
};


export type MutationupdateShopLocationArgs = {
  input: ShopLocationInput;
};


export type MutationupsertCampaignDefinitionArgs = {
  input: CampaignDefinitionInput;
};


export type MutationupsertCustomerArgs = {
  customer: CustomerInput;
};


export type MutationupsertDayReportArgs = {
  input: DayReportInput;
};


export type MutationupsertExpenseArgs = {
  input: ExpenseInput;
};


export type MutationupsertExpenseRefundArgs = {
  input: ExpenseRefundInput;
};


export type MutationupsertInventoryItemArgs = {
  input: InventoryItemInput;
};


export type MutationupsertInvoiceItemDefinitionArgs = {
  input: UpsertInvoiceItemDefinitionInput;
};


export type MutationupsertInvoiceItemGroupingArgs = {
  input: InvoiceItemGroupingInput;
};


export type MutationupsertInvoiceServiceDefinitionArgs = {
  input: UpsertInvoiceServiceDefinitionInput;
};


export type MutationupsertShopServiceArgs = {
  input: ShopServiceInput;
};

export type NewChatMessage = {
  __typename?: 'NewChatMessage';
  chatFeedMessagetype: ChatFeedMessageType;
  createdAt: Scalars['Date']['output'];
  employee?: Maybe<ChatMessageEmployee>;
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type OnboardingStatus = {
  __typename?: 'OnboardingStatus';
  isAccountComplete: Scalars['Boolean']['output'];
  isCompletelyOnboarded: Scalars['Boolean']['output'];
  isConnectedToSquare: Scalars['Boolean']['output'];
  isSquareSetupComplete: Scalars['Boolean']['output'];
  isTerminalConnected: Scalars['Boolean']['output'];
};

export type OnlinePaymentInput = {
  amount: AmountMoneyInput;
  jobId: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type Payment = {
  __typename?: 'Payment';
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  idempotencyKey?: Maybe<Scalars['String']['output']>;
  job?: Maybe<Job>;
  jobConnectionType?: Maybe<JobConnectionType>;
  jobId?: Maybe<Scalars['String']['output']>;
  refunds: Array<Refund>;
  settledAmount: Scalars['Int']['output'];
  shopLocation?: Maybe<ShopLocation>;
  squarePaymentId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<CardPaymentStatus>;
  terminalCheckoutId?: Maybe<Scalars['String']['output']>;
  type: PaymentType;
};

export type PaymentListReturn = {
  __typename?: 'PaymentListReturn';
  items: Array<Payment>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type PaymentRecordInput = {
  amount: AmountMoneyInput;
  id: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
  paymentType: RecordablePaymentType;
};

export enum PaymentType {
  CARD = 'CARD',
  CASH = 'CASH',
  CUSTOMER_ONLINE = 'CUSTOMER_ONLINE',
  INTERAC = 'INTERAC',
  ONLINE = 'ONLINE',
  UNKNOWN = 'UNKNOWN'
}

export enum PhoneNotificationTokenType {
  FCM = 'FCM'
}

export type PhoneNotificationsToken = {
  __typename?: 'PhoneNotificationsToken';
  token: Scalars['String']['output'];
  type: PhoneNotificationTokenType;
};

export type PhoneNotificationsTokenInput = {
  token: Scalars['String']['input'];
  type: PhoneNotificationTokenType;
};

export type PostalAddress = {
  __typename?: 'PostalAddress';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
};

export type PostalAddressInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  locality: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
};

export type PricingSection = {
  __typename?: 'PricingSection';
  buttons: Array<PricingSectionButton>;
  featureSubtitle: Scalars['String']['output'];
  features: Array<PricingSectionFeature>;
  pill?: Maybe<PricingSectionPill>;
  priceInCents: Scalars['Int']['output'];
  priceText: Scalars['String']['output'];
  subTitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type PricingSectionButton = {
  __typename?: 'PricingSectionButton';
  text: Scalars['String']['output'];
  type: PricingSectionButtonType;
  url: Scalars['String']['output'];
};

export enum PricingSectionButtonType {
  Primary = 'Primary',
  Secondary = 'Secondary'
}

export type PricingSectionFeature = {
  __typename?: 'PricingSectionFeature';
  title: Scalars['String']['output'];
};

export type PricingSectionPill = {
  __typename?: 'PricingSectionPill';
  text: Scalars['String']['output'];
  type: PricingSectionPillType;
};

export enum PricingSectionPillType {
  Success = 'Success'
}

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']['output']>;
  commandSearch: CommandSearchOutput;
  companyInformation: CustomerWorkOrderCompanyInformation;
  employeeQuery: EmployeeObject;
  generateLoginCodeForPhonNumber: GenerateLoginCodeForPhonNumberResult;
  getActivityFeedBadgeCount: Scalars['Int']['output'];
  getAllInvoiceItemDefinitions: GetAllInvoiceItemDefinitionsResponse;
  getAppointmentsForDropoffRange: ListAppointmentsResponse;
  getCampaignDefinition?: Maybe<CampaignDefinition>;
  getChatMessagesForSession: GetChatMessagesForSessionResponse;
  getChildUsers: Array<User>;
  getConnectedEmployerProfile: ConnectedEmployerProfile;
  getCurrentShift?: Maybe<Shift>;
  getCurrentShifts: Array<Shift>;
  getCustomer: Customer;
  getCustomerInvoiceOptions?: Maybe<CustomerInvoiceOptions>;
  getCustomerWorkOrder: CustomerWorkOrder;
  getCustomers: CustomerListReturn;
  getCustomersByPhoneNumber: Array<Customer>;
  getDashbaordData: DashboardData;
  getDeviceCode: Scalars['String']['output'];
  getEmployee?: Maybe<Employee>;
  getExpense: Expense;
  getExpenseUploadUrls: ExpenseUploadUrlsRespone;
  getFileUploadLinkByUploadCode: ExpenseFileUploadUrlResponse;
  getGPTDashboardData: GPTDashboardDataResponse;
  getJob: Job;
  getJobByJobNumber?: Maybe<Job>;
  getLocations: Array<SquareLocation>;
  getOnboardingStatus: OnboardingStatus;
  getPayment: Payment;
  getShopLocation: ShopLocation;
  getShopLocationPaymentStatus: ShopLocationPaymentStatus;
  getShopLocations: Array<ShopLocation>;
  getSquareConnectionForShopLocation?: Maybe<SquareConnection>;
  getSquareOAuthLink: Scalars['String']['output'];
  getStripeAccount: StripeConnectAccountDetails;
  getStripeAccountOnboardingLink: Scalars['String']['output'];
  getTodaysJobs: GetTodaysJobsResponse;
  getUser: User;
  listActivityFeedNotifications: AFNotificationList;
  listBankDeposits: ListBankDepositsResponse;
  listCampaignDefinitionOptions: CampaignDefinitionOptions;
  listCampaignDefinitions: CampaignDefinitionListReturn;
  listCars: CarListReturn;
  listChatsForCustomer: ListChatsForCustomerResponse;
  listConnectedEmployerProfile: ConnectedEmployerProfilesResult;
  listDayReports: ListDayReportsResponse;
  listEmployeeShifts?: Maybe<ShiftListReturn>;
  listEmployees: ListEmployeesResult;
  listEmployeesOnShift: ListEmployeesResult;
  listExpenseCategories?: Maybe<ExpenseCategoriesListReturn>;
  listExpenses: ExpenseListReturn;
  listInventoryItems: InventoryItemListReturn;
  listInvoiceItemDefinitions: ListInvoiceItemDefinitionsResponse;
  listInvoiceItemGroupings: ListInvoiceItemGroupingsResponse;
  listInvoiceServiceDefinitions: ListInvoiceServiceDefinitionsResponse;
  listJobs: JobListReturn;
  listJobsForDashboard: JobListReturn;
  listJobsForTable: JobListForTableReturn;
  listPastAppointments: ListAppointmentsResponse;
  listPayments: PaymentListReturn;
  listRequestedAppointments: Array<Appointment>;
  listShifts: ShiftListReturn;
  listShopServices: ShopServiceItemList;
  listUpcomingAppointments: ListAppointmentsResponse;
  login: AuthResponse;
  loginAsAdmin: AuthResponse;
  loginAsEmployee: AuthResponse;
  loginAsShop: AuthResponse;
  loginEmployee: LoginEmployeeAccountResult;
  loginEmployeeNew: AuthResponse;
  pricingSections: Array<PricingSection>;
  requestAppointmentCheckPhoneNumber: RequestAppointmentResponse;
  requestAppointmentPageMetadata: RequestAppointmentPageMetadata;
  searchCustomersBySearchTerm: SearchCustomersResponse;
  searchInvoiceItemDefinitions: SearchInvoiceItemDefinitionResponse;
  searchInvoiceServiceDefinitions: SearchInvoiceServiceDefinitionResponse;
  sendEmployeePhoneCode: EmployeeSendPhoneCodeResult;
  suggestCarEdit: CarEditSuggestion;
  verifyLoginWithPhoneNumberCode: AuthResponse;
};


export type QuerycommandSearchArgs = {
  input: CommandSearchInput;
};


export type QuerycompanyInformationArgs = {
  invoiceOptionsId: Scalars['String']['input'];
};


export type QueryemployeeQueryArgs = {
  employeeId: Scalars['String']['input'];
};


export type QuerygenerateLoginCodeForPhonNumberArgs = {
  input: GenerateLoginCodeForPhonNumberInput;
};


export type QuerygetAppointmentsForDropoffRangeArgs = {
  endTime: Scalars['Date']['input'];
  startTime: Scalars['Date']['input'];
};


export type QuerygetCampaignDefinitionArgs = {
  id: Scalars['String']['input'];
};


export type QuerygetChatMessagesForSessionArgs = {
  sessionId: Scalars['String']['input'];
};


export type QuerygetConnectedEmployerProfileArgs = {
  employeeId: Scalars['String']['input'];
};


export type QuerygetCustomerArgs = {
  id: Scalars['String']['input'];
};


export type QuerygetCustomerInvoiceOptionsArgs = {
  shopLocationId: Scalars['String']['input'];
};


export type QuerygetCustomerWorkOrderArgs = {
  workOrderId: Scalars['String']['input'];
};


export type QuerygetCustomersArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerygetCustomersByPhoneNumberArgs = {
  phoneNumber: Scalars['String']['input'];
};


export type QuerygetDashbaordDataArgs = {
  endTime: Scalars['Date']['input'];
  startTime: Scalars['Date']['input'];
};


export type QuerygetDeviceCodeArgs = {
  shopLocationId: Scalars['String']['input'];
  squareLocation: SquareLocationInput;
};


export type QuerygetEmployeeArgs = {
  id: Scalars['String']['input'];
};


export type QuerygetExpenseArgs = {
  id: Scalars['String']['input'];
};


export type QuerygetExpenseUploadUrlsArgs = {
  expenseId: Scalars['String']['input'];
};


export type QuerygetFileUploadLinkByUploadCodeArgs = {
  uploadId: Scalars['String']['input'];
};


export type QuerygetGPTDashboardDataArgs = {
  input: GPTDashboardDataInput;
};


export type QuerygetJobArgs = {
  id: Scalars['String']['input'];
};


export type QuerygetJobByJobNumberArgs = {
  jobNumber: Scalars['Int']['input'];
};


export type QuerygetLocationsArgs = {
  shopLocationId: Scalars['String']['input'];
};


export type QuerygetPaymentArgs = {
  jobId: Scalars['String']['input'];
  paymentId: Scalars['String']['input'];
};


export type QuerygetShopLocationArgs = {
  shopLocationId: Scalars['String']['input'];
};


export type QuerygetShopLocationPaymentStatusArgs = {
  shopLocationId: Scalars['String']['input'];
};


export type QuerygetSquareConnectionForShopLocationArgs = {
  shopLocationId: Scalars['String']['input'];
};


export type QuerygetSquareOAuthLinkArgs = {
  shopLocationId: Scalars['String']['input'];
};


export type QuerygetTodaysJobsArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  pageSize: Scalars['Int']['input'];
};


export type QuerylistActivityFeedNotificationsArgs = {
  filter?: InputMaybe<AFNotificationType>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerylistBankDepositsArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<ListOptions>;
};


export type QuerylistCampaignDefinitionsArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<ListOptions>;
};


export type QuerylistCarsArgs = {
  customerId: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerylistChatsForCustomerArgs = {
  customerId: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerylistDayReportsArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<ListOptions>;
  timezone: Scalars['String']['input'];
};


export type QuerylistEmployeeShiftsArgs = {
  employeeId: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<ListEmployeeShiftsOptions>;
};


export type QuerylistEmployeesArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerylistEmployeesOnShiftArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<ListEmployeesOnShiftOptions>;
};


export type QuerylistExpensesArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<ExpensesListOptions>;
};


export type QuerylistInventoryItemsArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerylistInvoiceItemDefinitionsArgs = {
  input: ListInvoiceItemDefinitionsInput;
};


export type QuerylistInvoiceItemGroupingsArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerylistInvoiceServiceDefinitionsArgs = {
  input: ListInvoiceServiceDefinitionsInput;
};


export type QuerylistJobsArgs = {
  input: ListJobsInput;
};


export type QuerylistJobsForDashboardArgs = {
  input: ListJobsForDashboardInput;
};


export type QuerylistJobsForTableArgs = {
  input: ListJobsInput;
};


export type QuerylistPastAppointmentsArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerylistPaymentsArgs = {
  input: ListPaymentsInput;
};


export type QuerylistShiftsArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  options: ListShiftsOptions;
};


export type QuerylistShopServicesArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerylistUpcomingAppointmentsArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryloginArgs = {
  input: LoginInput;
};


export type QueryloginAsEmployeeArgs = {
  employeeId: Scalars['String']['input'];
};


export type QueryloginAsShopArgs = {
  input: LoginAsShopInput;
};


export type QueryloginEmployeeArgs = {
  input: EmployeeLoginInput;
};


export type QueryloginEmployeeNewArgs = {
  input: LoginEmployeeInput;
};


export type QueryrequestAppointmentCheckPhoneNumberArgs = {
  locationShortName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type QueryrequestAppointmentPageMetadataArgs = {
  locationShortName: Scalars['String']['input'];
};


export type QuerysearchCustomersBySearchTermArgs = {
  searchTerm: Scalars['String']['input'];
};


export type QuerysearchInvoiceItemDefinitionsArgs = {
  searchString: Scalars['String']['input'];
};


export type QuerysearchInvoiceServiceDefinitionsArgs = {
  searchString: Scalars['String']['input'];
};


export type QuerysendEmployeePhoneCodeArgs = {
  input: EmployeeSendPhoneCodeInput;
};


export type QuerysuggestCarEditArgs = {
  car: CarEditSuggestionInput;
};


export type QueryverifyLoginWithPhoneNumberCodeArgs = {
  input: VerifyLoginWithPhoneNumberCodeInput;
};

export type RecommendationChatMessageResponse = {
  __typename?: 'RecommendationChatMessageResponse';
  id: Scalars['String']['output'];
  sessionId: Scalars['String']['output'];
};

export type RecommendationImage = {
  __typename?: 'RecommendationImage';
  imageId: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type RecommendationUploadUrl = {
  __typename?: 'RecommendationUploadUrl';
  expiresInSeconds: Scalars['Int']['output'];
  imageId: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum RecordablePaymentType {
  CASH = 'CASH',
  INTERAC = 'INTERAC'
}

export type Refund = {
  __typename?: 'Refund';
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  refundType: RefundType;
  settledAmount: AmountMoney;
  status: RefundStatus;
};

export type RefundPaymentInput = {
  amount: AmountMoneyInput;
  paymentId: Scalars['String']['input'];
};

export enum RefundStatus {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export enum RefundType {
  CASH = 'CASH',
  ETRANSFER = 'ETRANSFER',
  ONLINE_CARD = 'ONLINE_CARD',
  TERMINAL = 'TERMINAL'
}

export type RequestAppointmentInput = {
  carId?: InputMaybe<Scalars['String']['input']>;
  customerEmail?: InputMaybe<Scalars['String']['input']>;
  customerFirstName?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  customerLastName?: InputMaybe<Scalars['String']['input']>;
  customerPhoneNumber: Scalars['String']['input'];
  locationShortName: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  requestedAppointmentTime?: InputMaybe<Scalars['Date']['input']>;
};

export type RequestAppointmentPageMetadata = {
  __typename?: 'RequestAppointmentPageMetadata';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  companyWebsite?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
};

export type RequestAppointmentResponse = {
  __typename?: 'RequestAppointmentResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ResetPasswordInput = {
  code: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type SearchCustomerObject = {
  __typename?: 'SearchCustomerObject';
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  secondaryPhoneNumber?: Maybe<Scalars['String']['output']>;
  shippingAddress?: Maybe<PostalAddress>;
};

export type SearchCustomersResponse = {
  __typename?: 'SearchCustomersResponse';
  customers: Array<SearchCustomerObject>;
};

export type SearchInvoiceItemDefinitionResponse = {
  __typename?: 'SearchInvoiceItemDefinitionResponse';
  items: Array<InvoiceItemDefinition>;
};

export type SearchInvoiceServiceDefinitionResponse = {
  __typename?: 'SearchInvoiceServiceDefinitionResponse';
  items: Array<InvoiceServiceDefinition>;
};

export type SendResetPasswordEmailInput = {
  email: Scalars['String']['input'];
};

export type SendTextMessagesInput = {
  content: Scalars['String']['input'];
  customerId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type SendWorkOrderEmailInput = {
  body: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  toAddress: Scalars['String']['input'];
};

export type Shift = {
  __typename?: 'Shift';
  endTime?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  isApproved: Scalars['Boolean']['output'];
  shiftEmployee?: Maybe<ShiftEmployee>;
  startTime: Scalars['Date']['output'];
};

export type ShiftEmployee = {
  __typename?: 'ShiftEmployee';
  firstName: Scalars['String']['output'];
  hourlyRate?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type ShiftListReturn = {
  __typename?: 'ShiftListReturn';
  count: Scalars['Int']['output'];
  items: Array<Shift>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type ShopLocation = {
  __typename?: 'ShopLocation';
  currency?: Maybe<Currency>;
  defaultTaxRate?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ShopLocationInput = {
  currency?: InputMaybe<Currency>;
  defaultTaxRate?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type ShopLocationPaymentStatus = {
  __typename?: 'ShopLocationPaymentStatus';
  is_ready_to_take_payment_by_card_number: Scalars['Boolean']['output'];
  is_ready_to_take_payment_by_terminal: Scalars['Boolean']['output'];
};

export type ShopService = {
  __typename?: 'ShopService';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ShopServiceInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type ShopServiceItemList = {
  __typename?: 'ShopServiceItemList';
  items: Array<ShopService>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type SignupInput = {
  email: Scalars['String']['input'];
  info?: InputMaybe<UserInformationInput>;
  password: Scalars['String']['input'];
};

export type SquareAmountMoney = {
  __typename?: 'SquareAmountMoney';
  amount: Scalars['Int']['output'];
  currency: Currency;
};

export type SquareCatalogue = {
  __typename?: 'SquareCatalogue';
  items: Array<SquareItem>;
  taxes: Array<SquareTax>;
};

export type SquareConnection = {
  __typename?: 'SquareConnection';
  currency?: Maybe<Currency>;
  deviceIsPaired: Scalars['Boolean']['output'];
  isAuthorized: Scalars['Boolean']['output'];
  isSetupComplete: Scalars['Boolean']['output'];
  location?: Maybe<SquareLocation>;
  merchantId?: Maybe<Scalars['String']['output']>;
  syncedPaymentsUntil?: Maybe<Scalars['Date']['output']>;
};

export type SquareItem = {
  __typename?: 'SquareItem';
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  pricingType?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
};

export type SquareLocation = {
  __typename?: 'SquareLocation';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SquareLocationInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type SquareOptionsInput = {
  currency?: InputMaybe<Currency>;
  squareLocationId?: InputMaybe<Scalars['String']['input']>;
};

export type SquareTax = {
  __typename?: 'SquareTax';
  id: Scalars['String']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
};

export type SquareTokenResponse = {
  __typename?: 'SquareTokenResponse';
  access_token?: Maybe<Scalars['String']['output']>;
  expires_at?: Maybe<Scalars['String']['output']>;
  merchant_id?: Maybe<Scalars['String']['output']>;
  refresh_token?: Maybe<Scalars['String']['output']>;
  short_lived?: Maybe<Scalars['Boolean']['output']>;
  token_type?: Maybe<Scalars['String']['output']>;
};

export type StripeConnectAccountDetails = {
  __typename?: 'StripeConnectAccountDetails';
  charges_enabled: Scalars['Boolean']['output'];
  details_submitted: Scalars['Boolean']['output'];
  payouts_enabled: Scalars['Boolean']['output'];
};

export type StripePortalSession = {
  __typename?: 'StripePortalSession';
  url: Scalars['String']['output'];
};

export type StripeSubscriptionSession = {
  __typename?: 'StripeSubscriptionSession';
  url: Scalars['String']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  _empty?: Maybe<Scalars['String']['output']>;
  expensePicutureAdded: Expense;
  getAppointmentUpdates: Appointment;
  getEmployeeJobUpdated: Job;
  getJobUpdates: Job;
  getPaymentUpdates?: Maybe<Payment>;
  getRefundUpdates: Refund;
  newAfNotification: AFNotification;
  newMessageReceived?: Maybe<NewChatMessage>;
};


export type SubscriptionexpensePicutureAddedArgs = {
  expenseId: Scalars['String']['input'];
};


export type SubscriptiongetEmployeeJobUpdatedArgs = {
  jobId: Scalars['String']['input'];
};


export type SubscriptiongetJobUpdatesArgs = {
  jobId: Scalars['String']['input'];
};


export type SubscriptiongetPaymentUpdatesArgs = {
  jobId: Scalars['String']['input'];
};


export type SubscriptiongetRefundUpdatesArgs = {
  refundId: Scalars['String']['input'];
};


export type SubscriptionnewMessageReceivedArgs = {
  customerId: Scalars['String']['input'];
};

export enum SubscriptionStatus {
  active = 'active',
  canceled = 'canceled',
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  past_due = 'past_due',
  paused = 'paused',
  trialing = 'trialing',
  unpaid = 'unpaid'
}

export enum SubscriptionType {
  BASIC = 'BASIC'
}

export enum TextMessageSource {
  CUSTOMER = 'CUSTOMER',
  USER = 'USER'
}

export type TriggerCampaignSiteReturn = {
  __typename?: 'TriggerCampaignSiteReturn';
  triggerStatus: TriggerStatus;
};

export enum TriggerStatus {
  FAIL = 'FAIL',
  SUCCESS = 'SUCCESS'
}

export type UpdateAppointmentInput = {
  appointmentType?: InputMaybe<AppointmentType>;
  carId?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  estimatedPickupTime?: InputMaybe<Scalars['Date']['input']>;
  expectedDropoffTime: Scalars['Date']['input'];
  id: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AppointmentStatus>;
};

export type UpdateCarInput = {
  colour?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  trim?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateInvoiceNewResponse = {
  __typename?: 'UpdateInvoiceNewResponse';
  addNewService: Invoice;
  addServiceFromServiceDefinitionId: Invoice;
  deleteService: Scalars['Boolean']['output'];
  resolveInvoiceWarning: Scalars['Boolean']['output'];
  setNote: Invoice;
  updateItem: UpdateInvoiceServiceItemResponse;
  updateService: UpdateInvoiceServiceResponse;
};


export type UpdateInvoiceNewResponseaddNewServiceArgs = {
  newServiceDescription: Scalars['String']['input'];
  newServiceId: Scalars['String']['input'];
};


export type UpdateInvoiceNewResponseaddServiceFromServiceDefinitionIdArgs = {
  newServiceId: Scalars['String']['input'];
  serviceDefinitionId: Scalars['String']['input'];
};


export type UpdateInvoiceNewResponsedeleteServiceArgs = {
  serviceId: Scalars['String']['input'];
};


export type UpdateInvoiceNewResponseresolveInvoiceWarningArgs = {
  warningId: Scalars['String']['input'];
};


export type UpdateInvoiceNewResponsesetNoteArgs = {
  note: Scalars['String']['input'];
};


export type UpdateInvoiceNewResponseupdateItemArgs = {
  itemId: Scalars['String']['input'];
};


export type UpdateInvoiceNewResponseupdateServiceArgs = {
  serviceId: Scalars['String']['input'];
};

export type UpdateInvoiceResponse = {
  __typename?: 'UpdateInvoiceResponse';
  setNote?: Maybe<Invoice>;
};


export type UpdateInvoiceResponsesetNoteArgs = {
  note?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInvoiceServiceItemResponse = {
  __typename?: 'UpdateInvoiceServiceItemResponse';
  setCost: Scalars['Boolean']['output'];
  setDescription: Scalars['Boolean']['output'];
  setDiscount: Scalars['Boolean']['output'];
  setQuantity: Scalars['Boolean']['output'];
};


export type UpdateInvoiceServiceItemResponsesetCostArgs = {
  newCost: Scalars['Int']['input'];
};


export type UpdateInvoiceServiceItemResponsesetDescriptionArgs = {
  newDescription: Scalars['String']['input'];
};


export type UpdateInvoiceServiceItemResponsesetDiscountArgs = {
  newDiscount: Scalars['Int']['input'];
};


export type UpdateInvoiceServiceItemResponsesetQuantityArgs = {
  quantity: Scalars['Float']['input'];
};

export type UpdateInvoiceServiceResponse = {
  __typename?: 'UpdateInvoiceServiceResponse';
  addItem: Scalars['Boolean']['output'];
  deleteItem: Scalars['Boolean']['output'];
  setDescription: Scalars['Boolean']['output'];
};


export type UpdateInvoiceServiceResponseaddItemArgs = {
  invoiceItemId: Scalars['String']['input'];
  itemDefinitionId: Scalars['String']['input'];
};


export type UpdateInvoiceServiceResponsedeleteItemArgs = {
  itemId: Scalars['String']['input'];
};


export type UpdateInvoiceServiceResponsesetDescriptionArgs = {
  newDescription: Scalars['String']['input'];
};

export type UpdateJobResponse = {
  __typename?: 'UpdateJobResponse';
  removeJobCar: Scalars['Boolean']['output'];
  removeJobCustomer: Scalars['Boolean']['output'];
  removeJobEmployee: Scalars['Boolean']['output'];
  setCarOdometer: Scalars['Boolean']['output'];
  setJobCar: Scalars['Boolean']['output'];
  setJobCustomer: Scalars['Boolean']['output'];
  setJobEmployee: Scalars['Boolean']['output'];
  setJobWorkOrderMechanicStatus: Scalars['Boolean']['output'];
};


export type UpdateJobResponsesetCarOdometerArgs = {
  newOdometer: Scalars['Int']['input'];
};


export type UpdateJobResponsesetJobCarArgs = {
  carId: Scalars['String']['input'];
};


export type UpdateJobResponsesetJobCustomerArgs = {
  customerId: Scalars['String']['input'];
};


export type UpdateJobResponsesetJobEmployeeArgs = {
  employeeId: Scalars['String']['input'];
};


export type UpdateJobResponsesetJobWorkOrderMechanicStatusArgs = {
  status: JobMechanicShopStatus;
};

export type UpdateShiftInput = {
  endTime?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['String']['input'];
  isApproved: Scalars['Boolean']['input'];
  startTime?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateShiftResponse = {
  __typename?: 'UpdateShiftResponse';
  setEndTime: Shift;
  setIsApproved: Shift;
  setStartTime: Shift;
};


export type UpdateShiftResponsesetEndTimeArgs = {
  endTime: Scalars['Date']['input'];
};


export type UpdateShiftResponsesetIsApprovedArgs = {
  isApproved: Scalars['Boolean']['input'];
};


export type UpdateShiftResponsesetStartTimeArgs = {
  startTime: Scalars['Date']['input'];
};

export type UpsertInvoiceItemDefinitionInput = {
  cost: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  grouping_id: Scalars['String']['input'];
  id: Scalars['String']['input'];
  tax_percentage: Scalars['Float']['input'];
};

export type UpsertInvoiceServiceDefinitionInput = {
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
  itemIds: Array<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isEmailConfirmed?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  subscription?: Maybe<UserSubscription>;
  timezone?: Maybe<Scalars['String']['output']>;
  twillioPhoneNumber?: Maybe<Scalars['String']['output']>;
  type: AccountType;
};

export type UserInformationInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  twillioPhoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UserSubscription = {
  __typename?: 'UserSubscription';
  cancelsAt?: Maybe<Scalars['Date']['output']>;
  renewsOn?: Maybe<Scalars['Date']['output']>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  trialEnd?: Maybe<Scalars['Date']['output']>;
  type?: Maybe<SubscriptionType>;
};

export type VerifyLoginWithPhoneNumberCodeInput = {
  code: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type WaitlistItemInput = {
  email: Scalars['String']['input'];
};

export type WorkOrderRecommendation = {
  __typename?: 'WorkOrderRecommendation';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  priority?: Maybe<WorkOrderRecommendationPriority>;
  recommendationImageUploadUrl: RecommendationUploadUrl;
  recommendationImages: Array<RecommendationImage>;
  title?: Maybe<Scalars['String']['output']>;
};

export type WorkOrderRecommendationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  priority?: InputMaybe<WorkOrderRecommendationPriority>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type WorkOrderRecommendationMutation = {
  __typename?: 'WorkOrderRecommendationMutation';
  create: WorkOrderRecommendation;
  delete: Scalars['Boolean']['output'];
  deleteImage: Scalars['Boolean']['output'];
  setImageUploadIsSuccessful: RecommendationImage;
  update: WorkOrderRecommendation;
};


export type WorkOrderRecommendationMutationcreateArgs = {
  input: WorkOrderRecommendationInput;
  jobId: Scalars['String']['input'];
};


export type WorkOrderRecommendationMutationdeleteArgs = {
  input: DeleteWorkOrderRecommendationInput;
};


export type WorkOrderRecommendationMutationdeleteImageArgs = {
  input: DeleteWorkOrderRecommendationImageInput;
};


export type WorkOrderRecommendationMutationsetImageUploadIsSuccessfulArgs = {
  recommendationImageId: Scalars['String']['input'];
};


export type WorkOrderRecommendationMutationupdateArgs = {
  input: WorkOrderRecommendationInput;
};

export enum WorkOrderRecommendationPriority {
  Important = 'Important',
  Note = 'Note',
  Urgent = 'Urgent'
}
